import { supabase } from './src/scripts/supabase.js';
import { initializeHeader } from './src/scripts/header.js';
import { setupFooter, wrapContent } from './src/scripts/utils.js';
import './src/scripts/scroll-to-top.js';
import { initSponsoredLogos } from './src/scripts/sponsored-logos.js';

let modalOpenedFrom = null;
let isUserSubscribed = false;

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function toSentenceCase(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function getEnv(key, defaultValue) {
  if (window.ENV && window.ENV[key] !== undefined) {
    return window.ENV[key];
  }
  if (import.meta.env[key] !== undefined) {
    return import.meta.env[key];
  }
  return defaultValue;
}

const SUPABASE_URL = getEnv('VITE_SUPABASE_URL', '');
const SUPABASE_ANON_KEY = getEnv('VITE_SUPABASE_ANON_KEY', '');
const ENVIRONMENT = getEnv('ENVIRONMENT', 'production');
const FRONTEND_URL = getEnv('FRONTEND_URL', window.location.origin);
const STRIPE_PRICE_ID = getEnv('STRIPE_PRICE_ID', '');

async function initializePage() {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    if (window.location.pathname === '/' || window.location.pathname === '') {
      window.location.replace('/weekly-motion');
      return;
    }
    if (window.location.pathname !== '/new-session') {
      await handleIncompleteSubscription(session);
    }
    const { data: subscription } = await supabase
      .from('subscriptions')
      .select('price_id')
      .eq('email', session.user.email)
      .single();
    isUserSubscribed = !!subscription;
  } else if (window.location.pathname !== '/' && window.location.pathname !== '/new-session') {
    const pathParts = window.location.pathname.split('/').filter(Boolean);
    if (pathParts.length === 1 && pathParts[0].length === 36) {
      // Unauthenticated user accessing video, allowing...
    } else {
      window.location.href = '/';
      return;
    }
  }

  setupModalCloseButton();
  await loadMainPageContent();
  setupPopStateHandler();
  await handleVideoInUrl();
}

async function handleIncompleteSubscription(session) {
  try {
    const { data, error } = await supabase
      .from('subscriptions')
      .select('priceid')
      .eq('email', session.user.email)
      .single();

    if (error) throw error;

    if (!data || !data.priceid) {
      await supabase.auth.signOut();
      document.cookie = `supabase-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      localStorage.setItem('alertMessage', 'You do not have a subscription. Please subscribe to access more features.');
      window.location.href = '/';
    }
  } catch (error) {
    console.error('Error checking subscription:', error);
  }
}

async function loadSponsoredLogos() {
  const logos = await initSponsoredLogos('index', '#sponsored_logos_landing');
  const sponsoredLogosContainer = document.getElementById('sponsored_logos_container');
  
  if (!logos || logos.length === 0) {
    const videoWidth = window.innerWidth <= 768 ? '100%' : '80%';
    sponsoredLogosContainer.innerHTML = `
      <div class="flex justify-center items-center">
        <video autoplay loop muted playsinline style="width: ${videoWidth}; max-width: 600px; height: auto;">
          <source src="https://ezyuzghrwgctcmzczobw.supabase.co/storage/v1/object/public/assets/animations/dynamic-island-music-player-animation.webm?t=2024-08-26T09%3A01%3A08.663Z" type="video/webm">
          Your browser does not support the video tag.
        </video>
      </div>
    `;
    sponsoredLogosContainer.classList.remove('hidden');
  } else {
    sponsoredLogosContainer.classList.remove('hidden');
  }
}

async function loadMainPageContent() {
  const selectedCategory = getSelectedCategory();
  await loadVideos(selectedCategory);
  updateCategorySelection(selectedCategory);
}

function setupPopStateHandler() {
  window.addEventListener('popstate', async (event) => {
    const modal = document.getElementById('modal');
    if (modal && !modal.classList.contains('hidden')) {
      closeModal(true);
    }

    const pathParts = window.location.pathname.split('/').filter(Boolean);
    if (pathParts.length === 1 && pathParts[0].length === 36) {
      await handleVideoInUrl();
    } else if (window.location.pathname === '/') {
      await loadMainPageContent();
    } else {
      await loadMainPageContent();
    }
  });
}

async function handleVideoInUrl() {
  const pathParts = window.location.pathname.split('/').filter(Boolean);
  if (pathParts.length === 1 && pathParts[0].length === 36) {
    const videoId = pathParts[0];

    try {
      let { data: video, error: flowError } = await supabase
        .from('flow_videos')
        .select(`
          *,
          companies:company_id (
            company_name,
            normalised_company_name,
            logo_url,
            description
          )
        `)
        .eq('video_id', videoId)
        .single();

      if (!video || flowError) {
        let { data: interactionVideo, error: interactionError } = await supabase
          .from('interaction_videos')
          .select(`
            *,
            companies:company_id (
              company_name,
              normalised_company_name,
              logo_url,
              description
            )
          `)
          .eq('video_id', videoId)
          .single();

        if (interactionVideo) {
          video = interactionVideo;
        }
      }

      if (!video) {
        window.history.replaceState(null, '', '/');
        await loadMainPageContent();
        return;
      }

      if (document.getElementById('videosContainer').children.length === 0) {
        await loadMainPageContent();
      }

      await openModal(videoId, video.companies.normalised_company_name, '/');
    } catch (error) {
      console.error('Error checking video existence:', error);
      window.history.replaceState(null, '', '/');
      await loadMainPageContent();
    }
  }
}

function setupModalCloseButton() {
  const closeModalButton = document.getElementById('closeModalButton');
  if (closeModalButton) {
    closeModalButton.addEventListener('click', (event) => {
      event.preventDefault();
      closeModal(false);
    });
  }
}

function getSelectedCategory() {
  const pathParts = window.location.pathname.split('/').filter(part => part);
  return pathParts[0] || 'weekly-motion';
}

async function loadVideos(selectedCategory) {
  const videosContainer = document.getElementById('videosContainer');
  const videosCountElement = document.getElementById('videosCount');

  if (!videosContainer || !videosCountElement) {
    console.error('Essential elements are missing!');
    return;
  }

  videosContainer.innerHTML = '';

  try {
    let allVideos = [];

    let { data: flowVideos, error: flowError } = await supabase
      .from('flow_videos')
      .select(`
        *,
        companies:company_id (
          company_name,
          normalised_company_name,
          logo_url,
          description
        )
      `)
      .order('added_on', { ascending: false })
      .limit(16);

    if (flowError) throw flowError;
    allVideos = allVideos.concat(flowVideos);

    let { data: interactionVideos, error: interactionError } = await supabase
      .from('interaction_videos')
      .select(`
        *,
        companies:company_id (
          company_name,
          normalised_company_name,
          logo_url,
          description
        )
      `)
      .order('added_on', { ascending: false })
      .limit(16);

    if (interactionError) throw interactionError;
    allVideos = allVideos.concat(interactionVideos);

    allVideos = allVideos.sort(() => 0.5 - Math.random());

    let videosToDisplay = [];
    while (videosToDisplay.length < 16) {
      videosToDisplay = videosToDisplay.concat(allVideos);
    }
    videosToDisplay = videosToDisplay.slice(0, 16);

    videosToDisplay.sort(() => 0.5 - Math.random());

    const fragment = document.createDocumentFragment();
    videosToDisplay.forEach(video => {
      const videoElement = createVideoElement(video);
      if (videoElement) {
        fragment.appendChild(videoElement);
        if (isIOS()) {
          videoElement.querySelector('video').play().catch(() => {});
        }
      }
    });
    videosContainer.appendChild(fragment);

    const totalVideos = await fetchTotalVideos();

    const displayedVideos = videosContainer.children.length;
    const remainingVideosCount = totalVideos - displayedVideos;
    videosCountElement.querySelector('span').textContent = `${remainingVideosCount}`;

  } catch (error) {
    videosContainer.innerHTML = `<p class="text-center col-span-full">Error loading videos. Please try again later.</p>`;
  }
}

async function fetchTotalVideos() {
  const { data, error } = await supabase
    .from('video_counts')
    .select('total_videos')
    .single();

  if (error) {
    console.error('Error fetching total video count:', error);
    return 0;
  }

  return data.total_videos;
}

function createVideoElement(data) {
  const { video_url, companies, video_id } = data;
  if (!video_url) {
    console.error('Video URL missing for videoId:', video_id);
    return null;
  }

  const companyName = companies?.company_name ? toSentenceCase(companies.company_name) : 'Unknown Company';
  const companyLogo = companies?.logo_url || 'path/to/default/logo.png';
  const companySlug = companies?.normalised_company_name || companyName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  const companyUrl = `${window.location.origin}/${companySlug}`;

  const videoItem = document.createElement('div');
  videoItem.className = 'video-item grid-hover cursor-pointer';
  videoItem.innerHTML = `
      <div class="flex justify-center items-center w-full rounded-3xl bg-stone-100 h-72 p-4">
          <video src="${video_url}" class="rounded-2xl w-auto object-cover" style="height: 100%;" muted playsinline preload="auto" controlslist="nodownload noplaybackrate" disablePictureInPicture></video>
      </div>
      <div class="flex items-center text-sm company-link mt-2.5" data-company-url="${companyUrl}">
          <img src="${companyLogo}" class="w-8 h-8 rounded-full object-cover border border-stone-100 mr-1.5">
          <span class="font-semibold text-base hover:underline">${companyName}</span>
      </div>
  `;
  videoItem.dataset.videoId = video_id;

  const companyLinkElement = videoItem.querySelector('.company-link');
  companyLinkElement.addEventListener('click', function() {
    navigateToCompanyPage(companyUrl);
  });

  const videoElement = videoItem.querySelector('video');
  videoElement.addEventListener('error', () => {
    console.error(`Error loading video (${video_id}):`, videoElement.error);
    videoItem.remove();
  });

  setupVideoInteractions(videoItem, video_id);
  return videoItem;
}

function navigateToCompanyPage(companyUrl) {
  localStorage.setItem('previousUrl', window.location.pathname);
  window.location.href = companyUrl;
}

async function setupVideoInteractions(videoItem, videoId) {
  const videoElement = videoItem.querySelector('video');
  let isPlaying = false;

  if (!isIOS()) {
    videoItem.addEventListener('mouseover', () => {
      if (!isPlaying) {
        videoElement.play().then(() => {
          isPlaying = true;
        }).catch(error => {
          if (error.name !== 'AbortError') {
            console.error("Error playing video on hover: ", error);
          }
        });
      }
    });

    videoItem.addEventListener('mouseleave', () => {
      if (isPlaying) {
        videoElement.pause();
        videoElement.currentTime = 0;
        isPlaying = false;
      }
    });
  }

  videoElement.addEventListener('click', (event) => {
    event.preventDefault();
    openModal(videoId, videoItem.dataset.companySlug, window.location.pathname);
  });

  videoElement.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });

  videoElement.pause();
  videoElement.currentTime = 0;
}

function updateCategorySelection(selectedCategory) {
  const categoryTabs = document.querySelectorAll('.category-tab');
  categoryTabs.forEach(tab => {
    const isSelected = tab.dataset.category === selectedCategory;
    tab.classList.toggle('border-color-primary', isSelected);
    tab.classList.toggle('text-color-primary', isSelected);
    tab.classList.toggle('font-bold', isSelected);
    tab.classList.toggle('border-transparent', !isSelected);
    tab.classList.toggle('text-gray-500', !isSelected);
    tab.classList.toggle('hover:border-gray-200', !isSelected);
    tab.dataset.selected = isSelected.toString();
  });
}

async function openModal(videoId, companySlug, originalUrl = null) {
  const modal = document.getElementById('modal');
  const modalVideo = document.getElementById('modalVideo');
  const modalCompanyName = document.getElementById('modalCompanyName');

  if (!modal || !modalVideo || !modalCompanyName) {
    console.error('Essential modal elements are missing!');
    return;
  }

  try {
    let { data: video, error: flowError } = await supabase
      .from('flow_videos')
      .select(`
        *,
        companies:company_id (
          company_name,
          normalised_company_name,
          logo_url,
          description
        )
      `)
      .eq('video_id', videoId)
      .single();

    if (!video || flowError) {
      let { data: interactionVideo, error: interactionError } = await supabase
        .from('interaction_videos')
        .select(`
          *,
          companies:company_id (
            company_name,
            normalised_company_name,
            logo_url,
            description
          )
        `)
        .eq('video_id', videoId)
        .single();

      if (!interactionVideo || interactionError) {
        throw new Error('Video not found in both tables');
      }

      video = interactionVideo;
    }

    const { video_url, category, companies } = video;
    const { company_name, logo_url } = companies;
    const formattedCompanyName = toSentenceCase(company_name);

    if (!video_url) {
      throw new Error('No video URL found.');
    }

    companySlug = companySlug || companies.normalised_company_name;

    modalVideo.src = video_url;
    modalVideo.load();
    modalVideo.controlsList = isUserSubscribed ? "noplaybackrate" : "nodownload noplaybackrate";

    modalVideo.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      return false;
    });

    modalCompanyName.href = `/${companySlug}`;
    modalCompanyName.innerHTML = `<img src="${logo_url}" class="w-9 h-9 rounded-md object-cover bg-stone-100 mr-2" alt="${formattedCompanyName} Logo">${formattedCompanyName}`;
    modal.classList.remove('hidden');
    document.body.style.overflow = 'hidden';

    modalVideo.classList.toggle('video-fit-modal', category === 'android' || category === 'ios');

    const newUrl = `/${videoId}`;

    modalOpenedFrom = originalUrl || window.location.pathname;

    history.pushState(
      { 
        modalContext: {
          videoId,
          companySlug,
          originalUrl: modalOpenedFrom
        }
      },
      '',
      newUrl
    );
  } catch (error) {
    console.error('Error fetching video:', error);
    alert('Failed to load video. Please try again later.');
  }
}

function closeModal(isPopState = false) {
  const modal = document.getElementById('modal');
  const modalVideo = document.getElementById('modalVideo');
  modalVideo.pause();
  modal.classList.add('hidden');
  document.body.style.overflow = 'auto';

  if (!isPopState && modalOpenedFrom) {
      history.pushState(null, '', modalOpenedFrom);
      modalOpenedFrom = null;
  } else if (window.location.pathname.includes('/')) {
      history.pushState(null, '', '/');
  }
}

document.addEventListener('click', (event) => {
  const modal = document.getElementById('modal');
  if (modal && !modal.classList.contains('hidden') && event.target === modal) {
    closeModal(false);
  }
});

document.addEventListener('DOMContentLoaded', async () => {
  await initializePage();
  await initializeHeader();
  setupFooter();
  wrapContent();
  initSponsoredLogos('index', '#sponsored_logos_landing', false);
  await loadSponsoredLogos();
});